:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
}

@import "/theme/intendit/css/modules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/theme/intendit/css/modules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/theme/intendit/css/modules/slick.css";
@import "/theme/intendit/css/modules/slick-theme.css";
@import "/theme/intendit/css/modules/menu.css";
@import "/theme/intendit/css/modules/font-awesome.4.5.0.css";
@import "/theme/intendit/css/modules/magnific-popup.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/
.start-slider-slick {
    position: relative;
    overflow: hidden;
}
.slide-img {
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    height: 70vh;
}
.start-slider-slick .slick-content {
    position: absolute;
    height: 100%;
    top:0;
    left:0;
    right:0;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    text-align:center;
}
@media (max-width: 800px) {
    .slide-img { 
        height: 50vw;
    }
    .start-slider-slick .slick-content {
        position: relative;
        background:#59C35C;
    }
}


section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#2F7398;
}

.bg {
    /*background-image:url('/theme/intendit/img/gfx/footer-bg.png');*/
    background-repeat:repeat-x;
    background-position:top left;
}
    

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:80vh;
}

.page ul {
    margin:0 auto;
    padding:0;
    list-style-type: none;
}

.page li {
  padding-left: 1.2em;
}

.page li:before {
  content: "\f00c"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}
    

/* header
---------------------------------------------------------*/
header {
    background:white;
    text-transform:none;
    border-top:#59c35c solid 3px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header a {
    color:black;
}

.topout {
    background-color:#2E292E;
    position:relative;
    z-index:100;
}

nav {
    float:right;
}

nav li a {
    font-family: Crimson Text,serif;
    padding:10px 15px;
    color:black;
    font-size:1.2rem;
}

nav li a:hover, nav .active a {
    background:white;
    color:#59c35c;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin-bottom:0;
}

.slider-caption-outer .container {
    height: 100%;
    position: relative;
    text-align: left;
    display: inline-block;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:3rem;
}

.slick-dots {
    bottom:-60px;
}

.slick-dots li button:before {
    font-size:0.8rem;
    opacity:1.0;
    color:#fff;
}

.slick-dots li.slick-active button:before {
    color:#f2941c;
}

.slick-dots li {
    border:#D0D0D0 solid 1px;
    border-radius:50%;
    margin:0 2px;
}

.slick-dots li button {
    margin:0;
}

.slider-caption-outer {
    background:rgba(0,0,0,0.4);
}

.slider-caption {
    font-size:2.3rem;
    color:white;
}

.slider-caption .button {
    background:black;
    border:#101010 solid 1px;
    color:white;
}

.slider-caption .button:hover {
    background:#f2941c;
    border:#f2941c solid 1px;
    color:white;
}
    

/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.pop-img img {
    border-bottom:#80451D solid 4px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-rub h2 {
    color:#251408;
    font-size:2.0rem;
    font-weight:bold;
}

.boxLink a {
    color:#111;
}

.boxLink a:hover {
    color:#BF1717;
}

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {

}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* kontakt på vanliga sidor
---------------------------------------------------------*/
.block-kontakt h3 {
    margin-top:0;
    font-size:2.5rem;
}

.block-kontakt textarea {
    min-height:120px;
}

.kontakt .page {
    min-height:74vh;
}

.kontakt #kontakt {
    padding:0;
    min-height:74vh;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
    background: #59c35c;
    padding-bottom: 32px;
}

footer a {
    color: white;
    text-decoration:none;
}

.socialamedia a {
    font-size:3.0rem;
    padding-right:0.5em;
}

footer h3 {
    font-size:2.2rem;
    color:white;
    margin-bottom:0.5em;
}


/* other
---------------------------------------------------------*/

.boltform label {
    display: none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
}

a {
    color:#d50000;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#282d32;
}

button[type=submit], input[type=submit] {
    /*background-image:url('/theme/intendit/img/gfx/footer-bg.png');*/
    background: #59c35c;
    background-repeat:repeat-x;
    background-position:top left;
    border: #59c35c solid 1px;
    color: white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #222;
    border: #222 solid 1px;
    color: white;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
}

body {
    /*font-family: Verdana, Helvetica, Sans-serif;*/
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color:#282d32;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#222;
    font-family: Crimson Text,serif;
}

h1 {
    font-weight:700;
}

.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {}

@media (max-width: 750px) {}

@media (max-width: var(--breakpoint-menu)) {
    .menu {
        background:#eee !important;
    }
    header nav ul.menu a {
        color:black;
    }
    nav li a:hover, nav .active a {
        background:#eee;
    }
    .slider-caption-outer {
        background:transparent;
    }

}

@media (min-width: 990px) {
}

@media (min-width: var(--breakpoint-m)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 30%;
    width: 100%;
    right: 0;
    text-align: right;
    display: block;
    padding: 0;
    z-index: 3;
    }
}

@media (min-width: 550px) {
    .topmenu {
        margin-top:1.5em;
    }
    .start-block-wrapper4 h3 {
        font-size:3.0rem;
    }
}
